import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import useShowLoading from '@util/hook/useShowLoading';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { parseDate } from '@util/moment';
import { checkResponse } from '@apis/utils';

const GET_PEOPLE_API = '/api/people/';
const GET_EMPLOYEES_OPTIONS_API = `/api/employees/options/`;

const GET_INBODY_API = '/api/inbody/'; // 取得量身資料清單
const GET_INBODY_MEMBER_HEIGHT_API = '/api/inbody/member-height/'; // 取得會員身高
const GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = '/api/inbody/interview-not-measure-status/'; // 面談狀態
const PUT_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API;
const POST_INBODY_INTERVIEW_MEMBERID_API = (memberID) => `/api/inbody/interview/${memberID}/`; // 建立量身面談內容
const GET_INBODY_MEASURE_RECORDS_API = '/api/inbody/measure-records/'; // 取得完整量身資料清單
const GET_INBODY_TRIGGER_MEASURE_API = '/api/inbody/trigger-measure/'; // 觸發量身,回傳是否可使用
const POST_INBODY_MEASUREID_SPORT_ADVISE_SAVE_API = (measureID) => `/api/inbody/measure/${measureID}/sport-advise/save/`;
const POST_INBODY_MEMBERID_MEASURE_TASK_XFACTOR_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-xfactor/${measureID}/`;
const POST_INBODY_MEMBERID_MEASURE_TASK_AGREED_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-agreed/${measureID}/`;
const PUT_INBODY_INTERVIEW_API = `/api/inbody/interview/`;
const POST_CANCEL_MEASURE_API = `/api/inbody/cancel-measure/`;
const DELETE_INBODY_MEASUREID_API = (measureID) => `/api/inbody/${measureID}/`;
const POST_INBODY_IMPORT_EXCEL_API = `/api/inbody/import-inbody/`;
const POST_INBODY_IMPORT_EXCEL_370_DM0536_API = `/api/inbody/import-inbody/370_DM-0536/`;
const GET_INBODY_MEMBERID_MEASURE_RANGE_DATE_API = (memberID) => `/api/inbody/${memberID}/measure-range-date/`;

const useInBodyApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();
    const { getMemberProfileApi } = useMemberProfileApi();
    const showLoading = useShowLoading();

    // 歷史清單頁選項
    const getHistoryOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_PEOPLE_API, { type: 2 });
            if (!resp) enqueueSnackbar('發生錯誤，請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 歷史清單搜尋
    const getSearchHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_INBODY_API, params);
            if (!resp) enqueueSnackbar('發生錯誤，請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 面談初始資料
    const getInterviewInitDataApi = useCallback(
        async (id) => {
            let targetData = {};
            showLoading(true);
            // 摘要
            const profileData = await getMemberProfileApi(id);
            if (!profileData) enqueueSnackbar('摘要資料有誤，請重新整理', { variant: 'error' });
            else {
                const { resource, ...other } = profileData;
                Object.assign(targetData, { profileData: other });
                Object.assign(targetData, { options: resource });
            }
            // 量身紀錄
            let measureData = await Ajax.get(GET_INBODY_MEASURE_RECORDS_API, {
                referenceID: id,
                type: 2,
            });
            if (!measureData) enqueueSnackbar('量身紀錄有誤，請重新整理', { variant: 'error' });
            else {
                measureData = measureData.map(({ measureDataResponseModel, ...elseData }) => ({
                    ...measureDataResponseModel,
                    ...elseData,
                }));
                Object.assign(targetData, { measureData });
            }
            // 面談狀態
            const interviewStatus = await Ajax.get(GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API, {
                memberID: id,
            });
            if (!interviewStatus) enqueueSnackbar('面談不量身有誤，請重新整理', { variant: 'error' });
            else {
                Object.assign(targetData, interviewStatus);
            }

            // 會員身高
            const memberHeight = await Ajax.get(GET_INBODY_MEMBER_HEIGHT_API, { memberID: id });
            Object.assign(targetData, { memberHeight });

            // 教練
            const employeesOptions = await Ajax.get(GET_EMPLOYEES_OPTIONS_API);
            if (!employeesOptions) enqueueSnackbar('擔當資料有誤，請再操作一次', { variant: 'error' });
            else {
                Object.assign(targetData, { options: { ...targetData.options, employeesOptions } });
            }

            showLoading(false);
            return targetData;
        },
        // eslint-disable-next-line
        []
    );

    // 檢查是否可以量身
    const postTriggerMeasureApi = useCallback(
        async (params) => {
            const resp = await Ajax.post(GET_INBODY_TRIGGER_MEASURE_API, params);
            if (resp) {
                if (resp.isError) {
                    enqueueSnackbar(resp.message, { variant: 'error' });
                }
                return resp;
            }
        },
        // eslint-disable-next-line
        []
    );

    // 取消量身
    const postCancelMeasureApi = useCallback(
        async () => {
            return await Ajax.post(POST_CANCEL_MEASURE_API);
        },
        // eslint-disable-next-line
        []
    );

    // 刪除量身
    const deleteMeasureApi = useCallback(
        async (mID) => {
            const resp = await Ajax.delete(DELETE_INBODY_MEASUREID_API(mID)); // todo
            if (resp) {
                enqueueSnackbar('成功刪除', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗,請重新整理在刪除', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 修改 會員摘要 後需更新資料
    const getInterviewMemberProfileApi = useCallback(
        async (id) => {
            const targetData = {};
            const profileData = await getMemberProfileApi(id);
            if (profileData) {
                const { resource, ...other } = profileData;
                Object.assign(targetData, { profileData: other });
                Object.assign(targetData, { options: resource });
            }
            return targetData;
        },
        // eslint-disable-next-line
        []
    );

    // 修改 量身紀錄 後需更新資料
    const getInterviewMemberMeasureApi = useCallback(
        async (id) => {
            const targetData = {};
            let measureData = await Ajax.get(GET_INBODY_MEASURE_RECORDS_API, {
                referenceID: id,
                type: 2,
            });
            if (!measureData) enqueueSnackbar('量身紀錄有誤，請重新整理', { variant: 'error' });
            else {
                measureData = measureData.map(({ measureDataResponseModel, ...elseData }) => ({
                    ...measureDataResponseModel,
                    ...elseData,
                }));
                Object.assign(targetData, { measureData });
            }
            return targetData;
        },
        // eslint-disable-next-line
        []
    );

    // 修改是否量身資訊
    const putInterviewNotMeasureStatusApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API, params);
            if (!resp) enqueueSnackbar('傳輸有誤，請再重新操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 建立只面談不量身內容
    const postInterViewNotMeasureDataApi = useCallback(
        async (params, peopleID) => {
            const resp = await Ajax.post(POST_INBODY_INTERVIEW_MEMBERID_API(peopleID), params);
            if (resp) enqueueSnackbar('新增面談資料成功！！！', { variant: 'success' });
            else enqueueSnackbar('傳輸有誤，請再重新操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    //激勵因子修改
    const postXFactorApi = useCallback(
        async (memberID, measureID, params) => {
            const url = POST_INBODY_MEMBERID_MEASURE_TASK_XFACTOR_MEASUREID_API(memberID, measureID);
            const resp = await Ajax.post(url, params);
            if (resp) enqueueSnackbar('修改X-Factor成功', { variant: 'success' });
            else enqueueSnackbar('修改X-Factor失敗,請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    //約定事項修改
    const postAgreedApi = useCallback(
        async (memberID, measureID, params) => {
            const url = POST_INBODY_MEMBERID_MEASURE_TASK_AGREED_MEASUREID_API(memberID, measureID);
            const resp = await Ajax.post(url, params);
            if (resp) enqueueSnackbar('修改約定事項成功', { variant: 'success' });
            else enqueueSnackbar('修改約定事項失敗,請再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 量身面談筆記修改
    const putInterviewNotesApi = useCallback(
        async (params) => {
            const resp = await Ajax.put(PUT_INBODY_INTERVIEW_API, params);
            if (!resp) enqueueSnackbar('修改有誤，請重新再操作一次', { variant: 'error' });
            else enqueueSnackbar('修改成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 檢查是否有運動指引
    const checkSportAdviseSaveApi = useCallback(
        async (measureID) => {
            const resp = await Ajax.post(POST_INBODY_MEASUREID_SPORT_ADVISE_SAVE_API(measureID));
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postUploadInbodyExcelApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_INBODY_IMPORT_EXCEL_API, params, {
                headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
            });
            if (checkResponse(resp)) enqueueSnackbar('上傳成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    // 特殊版inbody
    const postUploadInbody370DM0536ExcelApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_INBODY_IMPORT_EXCEL_370_DM0536_API, params, {
                headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
            });
            if (checkResponse(resp)) enqueueSnackbar('上傳成功', { variant: 'success' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getMeasureRangeDateApi = useCallback(
        async (memberID, params) => {
            let resp = await Ajax.get(GET_INBODY_MEMBERID_MEASURE_RANGE_DATE_API(memberID), params);
            if (!resp) {
                enqueueSnackbar('讀取量身日期有誤，無法讀取，請重新整理', { variant: 'error' });
            } else {
                resp = resp.map((_d) => parseDate(_d));
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getHistoryOptionsApi,
        getSearchHistoryApi,
        getInterviewInitDataApi,
        postTriggerMeasureApi,
        getInterviewMemberProfileApi,
        getInterviewMemberMeasureApi,
        putInterviewNotMeasureStatusApi,
        postInterViewNotMeasureDataApi,
        postXFactorApi,
        postAgreedApi,
        putInterviewNotesApi,
        postCancelMeasureApi,
        deleteMeasureApi,
        postUploadInbodyExcelApi,
        postUploadInbody370DM0536ExcelApi,
        getMeasureRangeDateApi,
        checkSportAdviseSaveApi,
    };
};

export default useInBodyApi;
