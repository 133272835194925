import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { Alert, Box, Button, Card, CheckboxField, DateField, Form, MenuItem, RadioField, SelectField, TextField, Typography } from '@common/components/';
import { LeaveTextField, TermContent } from '@icoach/documents/components/';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { setCheckboxValue } from '@icoach/documents/tools';
import { dialogTypeMap } from '@icoach/documents/membership/staticData';
import { MembershipBillingRecordDialog, MembershipRecordDialog } from '@icoach/documents/membership/dialog';
import { default as termsData } from '@icoach/documents/membership/suspension/terms';
import { Dollar as DollarIcon, Edit as EditIcon, UserCircle as UserCircleIcon } from '@common/SvgIcon/';
import { ReadTextField, SignBox } from '@icoach/components/';
import UploadBox from '@icoach/components/UploadBox';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import { pathRouterShit, getErrorModuleStatus, isEmpty, isNumber, matchOptionsText, refIsRequiredError } from '@util/utils';
import { displayDateText, isValidDate, parseDate } from '@util/moment';
import useToggleDialog from '@util/hook/useToggleDialog';
import useShowPDF from '@util/hook/useShowPDF';
import useDocMembershipSuspendApi from '@apis/useDocMembershipSuspendApi';
import { DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER } from '@icoach/router/routerPath';

const WorkspaceBox = (props) => {
    const { onOpenDialog } = props;

    return (
        <Box className="workspace-box">
            <SpeedDial ariaLabel="SpeedDial openIcon example" icon={<SpeedDialIcon openIcon={<EditIcon />} />}>
                <SpeedDialAction icon={<UserCircleIcon />} tooltipTitle={'會籍紀錄'} onClick={() => onOpenDialog(dialogTypeMap.membershipRecord)} />
                <SpeedDialAction
                    icon={<DollarIcon htmlColor="#a45bc8" />}
                    tooltipTitle={'收費明細'}
                    onClick={() => onOpenDialog(dialogTypeMap.membershipBillingRecord)}
                />
            </SpeedDial>
        </Box>
    );
};

const MembershipSuspensionContent = (props) => {
    const { isReadOnly = false, onReturnHistory } = props;
    const {
        getDocSuspendContractApi,
        postDocSuspendContractApi,
        postDocTemporarySuspendContractApi,
        postUploadDocMembershipSuspendPlugin,
        downloadDocMembershipSuspendPDFApi,
        postDocMembershipSuspendRange,
    } = useDocMembershipSuspendApi();
    const history = useHistory();
    const { referenceID: referenceIDProps, contractID: contractIDProps, peopleType: peopleTypeProps } = useParams();
    const showPDF = useShowPDF();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [showPrompt, setShowPrompt] = useState(true);
    const [options, setOptions] = useState([]);
    const [isMounted, setMounted] = useState(false);
    const [errorModule, setErrorModule] = useState({});
    const [suspendType, setSuspendType] = useState('');
    const [suspendReasonType, setSuspendReasonType] = useState('');
    const [refreshKey, setRefreshKey] = useState(uuid());

    const applyDateRef = useRef(null);
    const employeeIDRef = useRef(null);
    const leaveReasonRef = useRef(null);
    const noteRef = useRef(null);
    const suspendReasonTextRef = useRef(null);
    const suspendDateLengthRef = useRef(null);
    const suspendStartDateRef = useRef(null);
    const isKeepDebitRef = useRef(null);
    const isConfirmRef = useRef(null);
    const checkNextMembershipDebitRef = useRef(null);
    const temporarySourceDataRef = useRef({});
    const uploadFileRef = useRef(null);
    const pathRef = useRef(null);
    const tokenRef = useRef(null);
    const signImgRef = useRef(null);
    const apiSendingRef = useRef(false);

    const { coachOptions, suspendTypeOptions, suspendReasonTypeOptions, paymentTypeOptions } = options;
    const {
        membershipID,
        contractID,
        memberID,
        applyDate,
        employeeID,
        plugIns = {},
        isConfirm,
        isCompleteContract, // 是否為正式文件
        signImg,
        contractVersion,
        contractVersionText,
        leaveReason,
        checkNextMembershipDebit,
        hasNewMembership,
        suspendReasonText,
        suspendDay,
        suspendMonth,
        suspensionDate,
        recoveryDate,
        suspendStartDate,
        suspendEndDate,
        warningMessage,
        paymentType,
        debitDayOfMonth,
        membershipStartDate,
        membershipEndDate,
        membershipExtensionDate,
        visibleOverNextSuspend = false,
        unusedMonth,
        unusedDay,
        usedMonth,
        usedDay,
        isKeepDebit,
    } = temporarySourceDataRef.current;

    const setParams = () => {
        if (isReadOnly) return false;
        const plug = {
            note: noteRef.current.getResult(),
            path: pathRef.current,
            token: tokenRef.current,
        };
        temporarySourceDataRef.current.plugIns = plug;
        temporarySourceDataRef.current.applyDate = applyDateRef.current.getResult();
        temporarySourceDataRef.current.employeeID = employeeIDRef.current.getResult();
        temporarySourceDataRef.current.isConfirm = isConfirmRef.current.getResult()[0].checked;
        temporarySourceDataRef.current.checkNextMembershipDebit = checkNextMembershipDebitRef.current
            ? checkNextMembershipDebitRef.current.getResult()[0].checked
            : false;
        temporarySourceDataRef.current.leaveReason = leaveReasonRef.current?.getResult() ?? '';
        temporarySourceDataRef.current.suspendType = suspendType;
        temporarySourceDataRef.current.suspendReasonType = suspendReasonType;
        temporarySourceDataRef.current.suspendReasonText = suspendReasonTextRef.current.getResult();
        temporarySourceDataRef.current.isKeepDebit = isKeepDebitRef.current.getResult()[0].checked;

        const { month, day } = suspendDateLengthRef.current.getResult();
        temporarySourceDataRef.current.suspendDay = day || 0;
        temporarySourceDataRef.current.suspendMonth = month || 0;
    };

    // 取得ref內儲存的所有變數
    const getParams = () => {
        return { ...temporarySourceDataRef.current };
    };

    // 設置區間參數 api回來的
    const setDateRangeParams = (params = {}) => {
        if (params.isAllowSuspend) {
            temporarySourceDataRef.current.membershipStartDate = params?.membershipStartDate;
            temporarySourceDataRef.current.membershipEndDate = params?.membershipEndDate;
            temporarySourceDataRef.current.membershipExtensionDate = params?.membershipExtensionDate;
            temporarySourceDataRef.current.suspendEndDate = params?.suspendEndDate;
            temporarySourceDataRef.current.suspendStartDate = params?.suspendStartDate;
            temporarySourceDataRef.current.suspensionDate = params?.suspensionDate;
            temporarySourceDataRef.current.recoveryDate = params?.recoveryDate;
            temporarySourceDataRef.current.visibleOverNextSuspend = params?.visibleOverNextSuspend;
            temporarySourceDataRef.current.paymentType = params?.paymentType;
            temporarySourceDataRef.current.debitDayOfMonth = params?.debitDayOfMonth;
            temporarySourceDataRef.current.hasNewMembership = params?.hasNewMembership;
        }
        temporarySourceDataRef.current.isAllowSuspend = params?.isAllowSuspend;
        temporarySourceDataRef.current.warningMessage = params?.warningMessage || '';
    };

    /** 畫面資料api異動 暫停假別(suspendType) 暫停開始時間(suspendStartDateRef) 暫停多久-月(suspendDateLengthRef) 暫停多久-日(suspendDateLengthRef) */
    const getRangeParams = () => {
        const {
            applyDate: applyDateParam,
            contractID: contractIDParam,
            suspendType: suspendTypeParam,
            isKeepDebit: isKeepDebitParam,
            suspendStartDate: suspendStartDateParam,
            suspendMonth: suspendMonthParam,
            suspendDay: suspendDayParam,
            memberID: memberIDParam,
        } = getParams();

        return {
            applyDate: applyDateParam || null,
            contractID: contractIDParam || 0,
            suspendType: suspendTypeParam,
            suspendStartDate: suspendStartDateParam,
            suspendMonth: suspendMonthParam,
            isKeepDebit: isKeepDebitParam,
            suspendDay: suspendDayParam || 0, // 原件之後要改 undefined時裡面帶0
            memberID: memberIDParam,
        };
    };

    // 送出 按鈕
    const handleSubmitCreate = () => {
        if (apiSendingRef.current) return false;
        const isError = refIsRequiredError(
            applyDateRef,
            employeeIDRef,
            noteRef,
            suspendReasonTextRef,
            suspendDateLengthRef,
            suspendStartDateRef,
            suspendStartDateRef,
            isKeepDebitRef,
            isConfirmRef,
            checkNextMembershipDebitRef
        );
        const file = uploadFileRef.current;
        temporarySourceDataRef.current.signImg = signImgRef.current.getResult();
        setParams();
        if (!isError) {
            apiSendingRef.current = true;
            if (file) {
                postUploadFileDataApi(file, contractIDProps);
            } else {
                doMembershipSuspensionContractApi(contractIDProps, getParams());
            }
        }
    };

    // 點擊暫存 按鈕
    const handleSubmitTemporary = () => {
        if (apiSendingRef.current) return false;
        setParams();
        apiSendingRef.current = true;
        doPostTemporaryDataApi(getParams());
    };

    // 上傳請假證明 事件
    const handleFileChange = (file) => {
        if (file) uploadFileRef.current = file;
    };

    // 上傳文件 API
    const postUploadFileDataApi = async (file, contractID) => {
        const formImgData = new FormData();
        formImgData.append('file', file, file.name);
        formImgData.append('contractID', contractID);
        const resp = await postUploadDocMembershipSuspendPlugin(contractID, formImgData);
        if (resp) {
            pathRef.current = resp;
            tokenRef.current = resp;
            setParams();
            doMembershipSuspensionContractApi(contractIDProps, getParams());
        } else {
            apiSendingRef.current = false;
        }
    };

    // 暫存 會籍暫停合約資料 API
    const doPostTemporaryDataApi = async (params) => {
        const resp = await postDocTemporarySuspendContractApi(params);
        if (resp) {
            setShowPrompt(false);
            onReturnHistory && onReturnHistory();
        } else {
            apiSendingRef.current = false;
        }
    };

    // 新增 會籍暫停合約資料 API
    const doMembershipSuspensionContractApi = async (contractID, params) => {
        const resp = await postDocSuspendContractApi(contractID, params);
        if (resp) {
            if (resp.isError) {
                setErrorModule(_.keyBy(resp.result, 'key'));
                apiSendingRef.current = false;
            } else {
                const routerPath = pathRouterShit(DOCUMENTS_MEMBERSHIPSUSPENSION_PEOPLETYPE_REFERENCEID_READONLY_CONTRACT_ROUTER, {
                    peopleType: peopleTypeProps,
                    referenceID: referenceIDProps,
                    contractID: contractID,
                });
                showPDF({
                    open: true,
                    pdfUrl: resp.completedPath,
                });
                setShowPrompt(false);
                window.setTimeout(() => {
                    history.push(routerPath);
                }, 5);
                setErrorModule({});
            }
        } else {
            setErrorModule({});
            apiSendingRef.current = false;
        }
    };

    // 初始會籍暫停資料 API
    const doInitDocMembershipSuspendDataApi = async () => {
        const resp = await getDocSuspendContractApi(contractIDProps, { id: referenceIDProps });
        if (resp) {
            const { resource, ...others } = resp;
            temporarySourceDataRef.current = others;
            setSuspendType(resp.suspendType);
            setSuspendReasonType(resp.suspendReasonType);
            setOptions(resource);
            setMounted(true);
        }
    };

    // 檢查api range回來的資料
    const postCheckDateRangeApi = async (params) => {
        const { suspendType, suspendStartDate, suspendMonth, suspendDay } = params;
        if (!suspendType || !isValidDate(suspendStartDate) || !isNumber(suspendMonth) || !isNumber(suspendDay)) return;
        const resp = await postDocMembershipSuspendRange(params);
        if (resp) {
            setDateRangeParams(resp);
            setRefreshKey(uuid());
        }
    };

    const handleChangeApplyDate = (e, value) => {
        temporarySourceDataRef.current.applyDate = value;
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    // 轉換 暫停假別 事件
    const handleSuspendTypeChange = (e, value) => {
        temporarySourceDataRef.current.suspendType = value;
        setSuspendType(value);

        // 暫停只能請月
        if (value === '1') {
            temporarySourceDataRef.current.suspendDay = 0;
        }

        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    // 暫停原因 事件 如果是其他原因說明為必填 9 為其他
    const handleSuspendReasonChange = (e, value) => {
        temporarySourceDataRef.current.suspendReasonType = value;
        setSuspendReasonType(value);
    };

    // 變換 暫停開始時間 事件
    const handleSuspendDataChange = (e, value) => {
        temporarySourceDataRef.current.suspendStartDate = value; // 待處理
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    // 變換 暫停多久-月 暫停多久-日 事件
    const handleSuspendMonthChange = (e, value) => {
        temporarySourceDataRef.current.suspendMonth = value;
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    const handleSuspendDayChange = (e, value) => {
        temporarySourceDataRef.current.suspendDay = value;
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    const handleExtensionChange = (e, value) => {
        temporarySourceDataRef.current.isKeepDebit = value;
        const params = getRangeParams();
        postCheckDateRangeApi(params);
    };

    useEffect(
        () => {
            doInitDocMembershipSuspendDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        []
    );

    const isShowDay = String(temporarySourceDataRef.current.suspendType) !== '1';
    const showWarningMessage = !temporarySourceDataRef.current?.isAllowSuspend && !isEmpty(temporarySourceDataRef.current?.warningMessage);

    return (
        <React.Fragment>
            {isMounted && (
                <React.Fragment>
                    <TermContent data={termsData[contractVersion] || []} className="mb-4" title="會員權暫停條款" subheader="依教育部體育署健身中心定型化契約" />
                    <Card>
                        <Card.CardContent className="px-4 pt-2 pb-1">
                            <Form name="create" onSubmit={handleSubmitCreate}>
                                <Box className="mt-3 mb-5">
                                    <FieldGroup label="表單資訊">
                                        <Grid item xs={4}>
                                            <DateField
                                                label="申請日期"
                                                ref={applyDateRef}
                                                defaultValue={parseDate(applyDate)}
                                                {...getErrorModuleStatus(errorModule, 'applyDate')}
                                                readOnly={isReadOnly}
                                                required
                                                fullWidth
                                                onChange={handleChangeApplyDate}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            {!isEmpty(coachOptions) && (
                                                <SelectField
                                                    label="經辦教練"
                                                    ref={employeeIDRef}
                                                    defaultValue={employeeID || null}
                                                    {...getErrorModuleStatus(errorModule, 'employeeID')}
                                                    readOnly={isReadOnly}
                                                    displayEmpty
                                                    required
                                                    fullWidth
                                                >
                                                    <MenuItem disabled value="">
                                                        <em>請選擇</em>
                                                    </MenuItem>
                                                    {coachOptions.map(({ text, value, disabled }) => (
                                                        <MenuItem key={value} value={value} disabled={disabled}>
                                                            {text}
                                                        </MenuItem>
                                                    ))}
                                                </SelectField>
                                            )}
                                        </Grid>
                                    </FieldGroup>
                                    <FieldGroup label="暫停資訊">
                                        <Grid item xs={6}>
                                            <ReadTextField label="待停扣月數">{unusedMonth}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ReadTextField label="待停扣天數">{unusedDay}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ReadTextField label="已停扣月數">{usedMonth}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ReadTextField label="已停扣天數">{usedDay}</ReadTextField>
                                        </Grid>
                                    </FieldGroup>
                                    <FieldGroup label="暫停設定">
                                        <Grid item xs={12}>
                                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                                我們邀請妳填寫以下資料，以協助妳完成後續的會員權暫停手續。
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {!isEmpty(suspendTypeOptions) && (
                                                <RadioField
                                                    label={'暫停假別'}
                                                    value={suspendType}
                                                    {...getErrorModuleStatus(errorModule, 'suspendType')}
                                                    readOnly={isReadOnly}
                                                    onChange={handleSuspendTypeChange}
                                                    required
                                                    row
                                                >
                                                    {suspendTypeOptions.map(({ text, value }) => (
                                                        <RadioField.Radio key={value} value={value} label={text} />
                                                    ))}
                                                </RadioField>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {!isEmpty(suspendReasonTypeOptions) && (
                                                <RadioField
                                                    label="暫停原因"
                                                    value={suspendReasonType}
                                                    {...getErrorModuleStatus(errorModule, 'suspendReasonType')}
                                                    onChange={handleSuspendReasonChange}
                                                    readOnly={isReadOnly}
                                                    fullWidth
                                                    required
                                                    row
                                                >
                                                    {suspendReasonTypeOptions.map((options) => (
                                                        <RadioField.Radio key={options.value} value={options.value} label={options.text} />
                                                    ))}
                                                </RadioField>
                                            )}
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                label="原因說明(字數限字20字)"
                                                ref={suspendReasonTextRef}
                                                defaultValue={suspendReasonText}
                                                {...getErrorModuleStatus(errorModule, 'suspendReasonText')}
                                                required={String(suspendReasonType) === '9'}
                                                readOnly={isReadOnly}
                                                maxLength={20}
                                                rows={2}
                                                multiline
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4} />
                                        <Grid item xs={4}>
                                            <DateField
                                                label="暫停開始時間"
                                                ref={suspendStartDateRef}
                                                defaultValue={parseDate(suspendStartDate)}
                                                onChange={handleSuspendDataChange}
                                                {...getErrorModuleStatus(errorModule, 'suspendStartDate')}
                                                readOnly={isReadOnly}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <LeaveTextField
                                                label="暫停多久"
                                                className="full-width"
                                                ref={suspendDateLengthRef}
                                                isShowDay={isShowDay}
                                                dayProps={{
                                                    type: 'number',
                                                    defaultValue: suspendDay,
                                                    onChangeNative: handleSuspendDayChange,
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                    disabled: isReadOnly,
                                                }}
                                                monthProps={{
                                                    type: 'number',
                                                    defaultValue: suspendMonth,
                                                    onChangeNative: handleSuspendMonthChange,
                                                    inputProps: {
                                                        min: 0,
                                                    },
                                                    disabled: isReadOnly,
                                                }}
                                                {...getErrorModuleStatus(errorModule, 'suspendDay')}
                                                fullWidth
                                                readOnly={isReadOnly}
                                                required
                                            />
                                        </Grid>
                                        <Grid item xs={8}>
                                            <ReadTextField label="暫停期間">{displayDateText(suspendStartDate, suspendEndDate)}</ReadTextField>
                                        </Grid>
                                        {showWarningMessage && (
                                            <Grid item xs={12}>
                                                <Alert severity="error" variant="filled" color="error" className="alert-justify-content-start">
                                                    {warningMessage}
                                                </Alert>
                                            </Grid>
                                        )}
                                    </FieldGroup>
                                    <FieldGroup label="扣款資訊">
                                        <Grid item xs={4}>
                                            {!isEmpty(paymentTypeOptions) && (
                                                <ReadTextField label="付款方式">{matchOptionsText(paymentTypeOptions, paymentType)}</ReadTextField>
                                            )}
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ReadTextField label="每月扣款日">{`${debitDayOfMonth} 號`}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={4}>
                                            <ReadTextField label="暫停扣款日">{parseDate(suspensionDate, 'YYYY/MM/DD')}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ReadTextField label="恢復扣款日">{parseDate(recoveryDate, 'YYYY/MM/DD')}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={4}></Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                                ref={isKeepDebitRef}
                                                defaultChecked={[!!isKeepDebit]}
                                                readOnly={isReadOnly}
                                                onChange={handleExtensionChange}
                                            >
                                                <CheckboxField.Checkbox label="此暫停申請書僅延長會籍，不執行停扣" value="isKeepDebit" />
                                            </CheckboxField>
                                        </Grid>
                                        {visibleOverNextSuspend && (
                                            <Grid item xs={8}>
                                                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                                    因超過20日提出申請，暫停扣款將從次次月開始停扣
                                                </Alert>
                                            </Grid>
                                        )}
                                    </FieldGroup>
                                    <FieldGroup label="會籍異動">
                                        <Grid item xs={8}>
                                            <ReadTextField label="會籍期間">{displayDateText(membershipStartDate, membershipEndDate)}</ReadTextField>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <ReadTextField key={refreshKey} label="更新後會籍">
                                                {displayDateText(membershipStartDate, membershipExtensionDate)}
                                            </ReadTextField>
                                        </Grid>
                                    </FieldGroup>
                                    {isCompleteContract && !isReadOnly && (
                                        <FieldGroup label="銷假原因" required>
                                            <Grid item xs={12}>
                                                <TextField
                                                    defaultValue={leaveReason}
                                                    ref={leaveReasonRef}
                                                    rows={6}
                                                    readOnly={isReadOnly}
                                                    multiline
                                                    required
                                                    fullWidth
                                                />
                                            </Grid>
                                        </FieldGroup>
                                    )}
                                    <FieldGroup label="茲附證明">
                                        <Grid item xs={12}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={6}>
                                                    <UploadBox
                                                        link={plugIns.path || null}
                                                        onFileChange={handleFileChange}
                                                        fileExtension={['jpg', 'png', 'bmp']}
                                                        readOnly={isReadOnly}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={`(字數限字100字)`}
                                                        ref={noteRef}
                                                        defaultValue={plugIns.notes || null}
                                                        maxLength={100}
                                                        readOnly={isReadOnly}
                                                        multiline
                                                        fullWidth
                                                        rows={4}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </FieldGroup>
                                    <FieldGroup label="注意事項">
                                        <Grid item xs={12}>
                                            <ol className="lists ol pl-3 mb-2">
                                                <li className="lists-item pt-1">
                                                    為配合銀行作業，會員須於暫停前最近一次扣款日之前十日辦理，暫停當月方可停扣月費，若辦理不及
                                                    則停扣作業將遞延，會員權益將不受影響。
                                                </li>
                                                <li className="lists-item">本文件辦理完畢後將直接生效，若有任何異動，仍需會員本人親自辦理。</li>
                                            </ol>
                                            <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                                暫停期間建議保持適度運動，相信妳了解持續運動帶給妳的好處，記得定時補充水份，保持正面心情，教練期待妳的歸隊！
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CheckboxField
                                                ref={isConfirmRef}
                                                defaultChecked={setCheckboxValue(isConfirm)}
                                                {...getErrorModuleStatus(errorModule, 'isConfirm')}
                                                readOnly={isReadOnly}
                                                required
                                            >
                                                <CheckboxField.Checkbox label="本人已瞭解、詳讀會員權暫停條款，並同意遵守。" />
                                            </CheckboxField>
                                        </Grid>
                                        {!isReadOnly && (
                                            <Grid item xs={6}>
                                                <SignBox
                                                    ref={signImgRef}
                                                    title="會員簽名"
                                                    defaultValue={signImg}
                                                    {...getErrorModuleStatus(errorModule, 'signImg')}
                                                    readOnly={isReadOnly}
                                                />
                                            </Grid>
                                        )}
                                    </FieldGroup>
                                    {hasNewMembership && (
                                        <FieldGroup label="教練注意">
                                            <Grid item xs={12}>
                                                <Box style={{ marginTop: '-6px' }}>
                                                    <CheckboxField
                                                        ref={checkNextMembershipDebitRef}
                                                        defaultChecked={setCheckboxValue(checkNextMembershipDebit)}
                                                        {...getErrorModuleStatus(errorModule, 'keyName')}
                                                        readOnly={isReadOnly}
                                                        required
                                                    >
                                                        <CheckboxField.Checkbox label="暫停扣款日跨越新舊合約，新合約起始時將無法自動停扣，請留意需停扣月份，並自行手動刪除批次扣款訂單。" />
                                                    </CheckboxField>
                                                </Box>
                                            </Grid>
                                        </FieldGroup>
                                    )}
                                </Box>
                                <Box className="btn-group text-center my-1">
                                    <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={onReturnHistory}>
                                        返回
                                    </Button>
                                    {!isReadOnly && (
                                        <React.Fragment>
                                            {!isCompleteContract && (
                                                <Button variant="outlined" color="secondary" className="btn-minWidth" onClick={handleSubmitTemporary}>
                                                    暫存檔案
                                                </Button>
                                            )}
                                            <Button type="submit" variant="contained" color="secondary" className="btn-minWidth">
                                                {isCompleteContract ? '確認修改' : '確認送出'}
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </Box>
                                <Typography variant="body2" className="text-center mb-0 px-2 font-color-3 my-4">
                                    {contractVersionText}
                                </Typography>
                            </Form>
                        </Card.CardContent>
                    </Card>
                    {Boolean(membershipID) && (
                        <WorkspaceBox
                            memberID={memberID}
                            contractID={contractID}
                            isCompleteContract={isCompleteContract}
                            onOpenDialog={handleOpenDialog}
                            downloadDocMembershipSuspendPDFApi={downloadDocMembershipSuspendPDFApi}
                        />
                    )}
                    <MembershipRecordDialog
                        open={openTarget === dialogTypeMap.membershipRecord}
                        memberID={memberID}
                        membershipID={membershipID}
                        onClose={handleCloseDialog}
                    />
                    <MembershipBillingRecordDialog
                        open={openTarget === dialogTypeMap.membershipBillingRecord}
                        memberID={memberID}
                        membershipID={membershipID}
                        onClose={handleCloseDialog}
                    />
                    {!isReadOnly && <PromptDialog when={showPrompt} />}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MembershipSuspensionContent;
