import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Box, Chip, Stack, Typography } from '@common/components/';
import { formatCurrencyFn, isEmpty } from '@util/utils';
import { useCyclePurchaseContext } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH, DOCUMENT_CYCLE_PURCHASE_PATH } from '@icoach/router/routerPath';
import { BusinessCardBox, MoreLabelBox, MorePageBox, PageSortAndTotalBox } from '@icoach/components';
import Tablenization from '@icoach/components/Tablenization';
import { CycleOrderDeleteDialog } from '@icoach/documents/dialog';
import { parseDate } from '@util/moment';
import { COLOR_MATCH } from '@icoach/documents/cyclePurchase/staticData';
import clsx from 'clsx';

const MainMoreLabel = (props) => {
    const { memberID, cycleOrderID } = props;
    const { refresh } = useCyclePurchaseContext();
    const moreLabelMap = useRef({
        view: {
            text: '查看',
            to: () => DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID),
        },
        modify: {
            text: '變更申請書',
            to: () => DOCUMENT_CYCLE_PURCHASE_EFFECT_PATH(memberID, cycleOrderID),
        },
        delete: {
            text: '刪除申請書',
            dialogString: 'delete',
            Dialog: CycleOrderDeleteDialog,
        },
    });

    return <MoreLabelBox {...props} memberID={0} moreLabelMap={moreLabelMap.current} textAlign={'center'} refresh={refresh.current} />;
};

const CycleOrderListBox = (props) => {
    const { orderData = [], shippingDayText = '10' } = props;
    return (
        <React.Fragment>
            <Box className={'font-color-3'}>{`出貨週期：${shippingDayText}日`}</Box>
            {!isEmpty(orderData) && (
                <Box>
                    {orderData.map(({ cycleOrderItemID, productName, quantity = 1 }) => {
                        return <Box key={cycleOrderItemID}>{`${productName} x ${quantity}`}</Box>;
                    })}
                </Box>
            )}
        </React.Fragment>
    );
};

const rowsLabel = [
    {
        cellKey: 'cycleOrderNo',
        headerLabel: '循環訂單編號',
        width: '12',
        align: 'center',
        formatCell: (cellValue, { memberID, cycleOrderID, statusColor, cycleOrderTypeText }) => (
            <Stack direction={'column'}>
                <Link className={'text-decoration-underline text-secondary mb-1'} to={DOCUMENT_CYCLE_PURCHASE_PATH(memberID, cycleOrderID)}>
                    {cellValue}
                </Link>
                <Chip label={cycleOrderTypeText} color={COLOR_MATCH[statusColor]} title={'訂單狀態'} />
            </Stack>
        ),
    },
    {
        cellKey: 'memberName',
        headerLabel: '會員',
        formatCell: (memberName, { memberNo, nickName, memberImg, membershipStatus }) => {
            let memberInfo = {
                memberName,
                memberNo,
                nickName,
                memberImg,
                membershipStatus,
            };
            return <BusinessCardBox rowData={memberInfo} />;
        },
    },
    {
        cellKey: 'items',
        headerLabel: '訂單內容',
        formatCell: (value, { shippingDayText }) => {
            return <CycleOrderListBox orderData={value} shippingDayText={shippingDayText} />;
        },
    },
    {
        cellKey: 'saleAmount',
        headerLabel: '訂單金額',
        align: 'center',
        formatCell: (cellValue) => formatCurrencyFn(cellValue),
    },
    {
        cellKey: 'cardExpiryDate',
        headerLabel: '信用卡效期',
        align: 'center',
        formatCell: (cellValue) => {
            const cardExpiryDate = cellValue || '0000'.split('');
            return `${cardExpiryDate[0]}${cardExpiryDate[1]}月/${cardExpiryDate[2]}${cardExpiryDate[3]}年`;
        },
    },
    {
        cellKey: 'updatedDate',
        headerLabel: '更新日期',
        width: '12',
        formatCell: (cellValue) => {
            const _parseDate = (date, format) => {
                return parseDate(date, format, false);
            };
            return (
                <React.Fragment>
                    <Typography className="mb-0">{_parseDate(cellValue, 'YYYY/MM/DD')}</Typography>
                    <Typography className="mb-0 font-color-3">{_parseDate(cellValue, 'HH:mm')}</Typography>
                </React.Fragment>
            );
        },
    },
    {
        cellKey: 'employeeName',
        headerLabel: '擔當',
        align: 'center',
    },
    {
        cellKey: 'action',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (_, rowData) => {
            const { canDeleted, canEffection } = rowData;
            let moreAction = {
                view: true,
                modify: canEffection,
                delete: canDeleted,
            };
            let getMoreActionArray = () => {
                let array = [];
                for (let key in moreAction) {
                    let bool;
                    if (moreAction.hasOwnProperty(key)) {
                        bool = moreAction[key];
                    }
                    if (bool) {
                        array.push(key);
                    }
                }
                return array;
            };
            return <MainMoreLabel {...rowData} moreActionArray={getMoreActionArray()} />;
        },
    },
];

const CyclePurchaseOverviewTable = (props) => {
    const { className, sortDefaultValue } = props;
    const { tableData, setBookmarkNum, setSortBy, options } = useCyclePurchaseContext();
    const { pageIndex = 1, pageSize = 0, totalPage = 1, totalCount = 0, list: rowsData = [] } = tableData || {};
    const { cycleOrderOrderByOptions = [] } = options || {};
    const labelTitle = useRef(rowsLabel);
    const handlePageChange = (e, num) => setBookmarkNum(num);

    return (
        <React.Fragment>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'end'}>
                <PageSortAndTotalBox
                    className={'mb-1'}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    sortDefaultValue={sortDefaultValue}
                    sortOptions={cycleOrderOrderByOptions}
                    sortOnChange={setSortBy}
                    isCurrentPageLastIndex
                    isSort
                />
            </Stack>
            <Tablenization
                Pagination={MorePageBox}
                count={totalPage}
                page={pageIndex}
                cardProps={{ className: clsx(className, 'overview') }}
                onChange={handlePageChange}
                isPagination
            >
                <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                <Tablenization.TableRowBody headerRow={labelTitle.current} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default CyclePurchaseOverviewTable;
