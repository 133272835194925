import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getVersionData } from '@redux/version/selector';
import clsx from 'clsx';
import InBodyInterviewDialogBox from '@icoach/inbody/interview/InBodyInterviewDialogBox';
import InBodyInterviewMeasureNormalBox, { inBodyInterviewMeasureItems } from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { Box, Card } from '@common/components/';

const InBodyInterviewMeasureTable = React.forwardRef((props, ref) => {
    const { sourceData = [] } = props;
    const reduxVersion = useSelector(getVersionData);
    let { env: ENV = '' } = reduxVersion;
    const divRef = useRef(null);
    const tableContainerRef = useRef(null);
    const frozenCellsRef = useRef([]);

    useEffect(() => {
        const tableContainer = tableContainerRef.current;
        const handleScroll = () => {
            const scrollLeft = tableContainer.scrollLeft;

            // 固定左側
            frozenCellsRef.current.forEach((cell) => {
                if (scrollLeft > 0) {
                    cell.style.position = 'sticky';
                    cell.style.left = '0';
                    cell.style.zIndex = '10';
                } else {
                    cell.style.position = 'static';
                }
            });
        };

        tableContainer.addEventListener('scroll', handleScroll);

        return () => {
            tableContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Card>
            <Card.CardContent>
                <Box ref={tableContainerRef} className={'inbody-measure scroll-arrow-container'}>
                    <Box className={'container'} ref={divRef}>
                        {inBodyInterviewMeasureItems.map(
                            ({ value, key, rowClass, formatCell, formatCompare, formatMutipleCell, style: styleProps = {}, env }, i) => {
                                if (env && !env.includes(String(ENV).toUpperCase())) {
                                    return null;
                                }
                                let styles = { ...styleProps };
                                return (
                                    // row
                                    <Box key={value} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')}>
                                        {/* Column 標題 */}
                                        <Box
                                            ref={(el) => (frozenCellsRef.current[i] = el)}
                                            className={clsx('item', 'sticky', 'label')}
                                            style={{ width: '180px' }}
                                        >
                                            {value}
                                        </Box>
                                        {/*/!* 全部內容 *!/*/}
                                        <InBodyInterviewMeasureNormalBox sourceData={sourceData} itemKey={key} styles={styles} formatCell={formatCell} />
                                        <InBodyExtendDiv />
                                    </Box>
                                );
                            }
                        )}
                    </Box>
                    <ScrollerArrowButton arrowLeftPos={600} parentRef={divRef} />
                    <InBodyInterviewDialogBox />
                </Box>
            </Card.CardContent>
        </Card>
    );
});

export default InBodyInterviewMeasureTable;
