import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { isEmpty, merge } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useMembershipContext } from '@icoach/documents/membership/create/MembershipContext';
import {
    TextField,
    RadioField,
    CheckboxField,
    SelectField,
    SourceTypeSelectField,
    MenuItem,
    DateField,
    DateIntervalField,
    Alert,
    Button,
} from '@common/components/';
import { Promotions } from '@icoach/documents/components';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { setCheckboxValue, getRadioValue } from '@icoach/documents/tools';
import { initialDate, isAfter, isValidDate, parseDate, getFirstOfDay, getLastOfDay } from '@util/moment';
import { pathRouterShit, refIsRequiredError } from '@util/utils';
import { peopleType } from '@apis/usePeopleApi';
import { DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER } from '@icoach/router/routerPath';
import { DateTimeStandard } from '../../../../components/pickers/enums/DateTimeStandard';

export const handlePlansData = (current, result = {}) => {
    const { contractMain } = current;
    if (contractMain && contractMain.hasOwnProperty('plans')) {
        result.contractMain.plans = contractMain.plans;
    }
    return result;
};

// 入會方案
const MembershipCreateContentPlan = React.forwardRef((props, ref) => {
    const { data, options, errorModule, isReadOnly, getErrorModuleStatus, handleChangeRangeFee } = useMembershipContext();
    const {
        memberID,
        applyDate,
        faDate,
        employeeID,
        targetType,
        canModifyMembershipTerm, // 是否可編輯會籍合約時間
        canChangeMembershipTerm, // 是否可以修改會籍期間:條件-合約尚未開始 且只能一年改兩年
        canExchange, //是否顯示轉約提示(條件 合約已開始且合約開始時間三個月內)
        contractVersion,
        contractMain = {},
        contractFee = {},
        canTransferMemberNo = false,
    } = data;
    const {
        startDate,
        endDate,
        contractStartDate,
        contractEndDate,
        reviewStartDate,
        reviewEndDate,
        transfereeMemberName,
        transfereeMemberNo,
        useTransfereeMemberNo,
        recoveryType,
        sourceTag,
        memberType,
        paymentType,
        membershipTerm,
        plans,
        planTab,
        notes,
    } = contractMain;
    const { joinFee, monthlyFee } = contractFee;
    const {
        coachOptions,
        membershipTypeOptions,
        monthlyPlan,
        enterprisePlan,
        otherPlan,
        contractPlan,
        membershipMemberType: membershipMemberTypeOptions,
        membershipTerm: membershipTermOptions,
        paymentType: paymentTypeOptions,
        resumeSourceType: resumeSourceTypeOptions,
        sourceType: sourceTypeOption,
    } = options;
    const history = useHistory();
    const [membershipType, setMembershipType] = useState(0);
    const [invalidMembershipStart, setInvalidMembershipStart] = useState(false);
    const fieldRef = useRef({});
    const contractMainFieldRef = useRef({});
    const contractMainDateRef = useRef({});
    const contractFeeFieldRef = useRef({});
    const timeoutIDRef = useRef(null); // Allen 目前只有這個地方使用 如果他地方有用就寫成hook
    const isInitMembershipTypeRef = useRef(true); // 是否從後端第一次取得的資料

    //targetType 1顧客 2會員 3無效會員 4承接會員
    const handleMembershipTypeOnChange = (e, value) => {
        setMembershipType(value);
    };

    const getResult = () => {
        const result = { contractMain: {}, contractFee: {} };
        const fieldObj = fieldRef.current;
        const contractMainFieldObj = contractMainFieldRef.current;
        const contractMainDateObj = contractMainDateRef.current;
        const contractFeeFieldObj = contractFeeFieldRef.current;

        if (fieldObj) {
            for (let key in fieldObj) {
                const target = fieldObj[key];
                if (target && target.getResult) {
                    let val = target.getResult();
                    if (key === 'applyDate' || key === 'faDate' || key === '') {
                        val = parseDate(val, DateTimeStandard.DisplayUtcFull);
                    }
                    result[key] = val;
                }
            }
        }
        if (contractMainFieldObj) {
            for (let key in contractMainFieldObj) {
                const target = contractMainFieldObj[key];
                if (target && target.getResult) {
                    let val = target.getResult();

                    if (key !== 'plans' && !isEmpty(val) && Array.isArray(val)) {
                        val = getRadioValue(val);
                    }
                    if (key === 'useTransfereeMemberNo') {
                        const { checked = false } = target.getResult()[0];
                        val = checked;
                    }
                    if (key === 'recoveryType' && isEmpty(val)) {
                        val = 0;
                    }

                    if (key === 'sourceTypeSelector') {
                        const { sourceType, sourceTag } = val;

                        result.contractMain['recoveryType'] = !isEmpty(sourceType) ? sourceType : '0';
                        result.contractMain['sourceTag'] = sourceTag;
                    } else {
                        result.contractMain[key] = val;
                    }
                }
            }
        }
        if (contractMainDateObj) {
            for (let key in contractMainDateObj) {
                const target = contractMainDateObj[key];
                if (target && target.getResult) {
                    const { startDate, endDate } = target.getResult();
                    if (key === '_') {
                        if (startDate) {
                            result.contractMain.startDate = parseDate(startDate, DateTimeStandard.DisplayUtcFull);
                        }

                        if (endDate) {
                            result.contractMain.endDate = parseDate(endDate, DateTimeStandard.DisplayUtcFull);
                        }
                    } else {
                        if (startDate) {
                            result.contractMain[`${key}StartDate`] = parseDate(startDate, DateTimeStandard.DisplayUtcFull);
                        }

                        if (endDate) {
                            result.contractMain[`${key}EndDate`] = parseDate(endDate, DateTimeStandard.DisplayUtcFull);
                        }
                    }
                }
            }
        }
        if (contractFeeFieldObj) {
            for (let key in contractFeeFieldObj) {
                const target = contractFeeFieldObj[key];

                if (target && target.getResult) {
                    let val = target.getResult();
                    if (!isEmpty(val) && Array.isArray(val)) {
                        val = getRadioValue(val);
                    }
                    result.contractFee[key] = val;
                }
            }
        }

        return result;
    };

    const handleDateRangeFeeChange = (targetData) => {
        window.clearTimeout(timeoutIDRef.current);

        const params = handleParams(targetData);

        setInvalidMembershipStart(false);

        timeoutIDRef.current = window.setTimeout(() => {
            handleChangeRangeFee(params);
        }, 1500);
    };

    const handleMembershipDateRangeFeeChange = (targetData) => {
        window.clearTimeout(timeoutIDRef.current);

        if (!!targetData.contractMain && !isValidDate(targetData.contractMain.startDate)) return;

        const params = handleParams(targetData);

        let compareReview = getLastOfDay(reviewEndDate);
        let compareMembership = getFirstOfDay(params.contractMain.startDate);

        if (params.contractMain.membershipType === '1' && isAfter(compareReview, compareMembership, 'YYYY-MM-DDTHH:mm')) {
            setInvalidMembershipStart(true);
            return;
        } else {
            setInvalidMembershipStart(false);
        }

        timeoutIDRef.current = window.setTimeout(() => {
            handleChangeRangeFee(params);
        }, 1500);
    };

    const handleParams = (params) => {
        let result = getResult();
        result = merge(result, params);
        // @ts-ignore
        result = handlePlansData(params, result);
        if (!isEmpty(params) && params.hasOwnProperty('applyDate')) result.contractMain.startDate = '';
        return result;
    };

    // 前往申請書頁面
    const goToContractPage = (memberID) => {
        const path = pathRouterShit(DOCUMENTS_MEMBERSHIP_PEOPLETYPE_REFERENCEID_CONTRACT_ROUTER, {
            peopleType: 2,
            referenceID: memberID,
            contractID: 0,
        });
        history.replace(path);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult,
            isError: () => {
                const allObj = {
                    ...fieldRef.current,
                    ...contractMainFieldRef.current,
                    ...contractMainDateRef.current,
                    ...contractFeeFieldRef.current,
                };
                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (!isEmpty(data) && String(membershipType) === '0') setMembershipType(data.contractMain.membershipType);
        },
        // eslint-disable-next-line
        [data],
    );

    useEffect(
        () => {
            if (String(membershipType) !== '0') {
                if (!isInitMembershipTypeRef.current) {
                    handleChangeRangeFee(handleParams({ contractMain: { membershipType } }));
                } else {
                    isInitMembershipTypeRef.current = false;
                }

                let compareReview = getLastOfDay(reviewEndDate);
                let compareMembership = getFirstOfDay(startDate);

                if (String(membershipType) === '1' && isAfter(compareReview, compareMembership, 'YYYY-MM-DDTHH:mm')) {
                    setInvalidMembershipStart(true);
                } else {
                    setInvalidMembershipStart(false);
                }
            }
        },
        // eslint-disable-next-line
        [membershipType],
    );

    useEffect(
        () => {
            return () => {
                window.clearTimeout(timeoutIDRef.current);
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            {String(targetType) === peopleType.onlyJoin && (
                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                    使用「新入會」將會紀錄一次詢問、APO與FA喔！
                </Alert>
            )}
            <FieldGroup label="合約資訊">
                {String(contractVersion) === '101' && (
                    <Grid item xs={4}>
                        <DateField
                            key={initialDate(applyDate)}
                            ref={(el) => (fieldRef.current.applyDate = el)}
                            defaultValue={initialDate(applyDate)}
                            label={'申請日期'}
                            onChange={(_, val) => handleDateRangeFeeChange({ applyDate: val })}
                            {...getErrorModuleStatus(errorModule.contractMain, 'applyDate')}
                            readOnly={isReadOnly}
                            fullWidth
                            required
                        />
                    </Grid>
                )}
                <Grid item xs={4}>
                    <DateField
                        key={initialDate(faDate)}
                        ref={(el) => (fieldRef.current.faDate = el)}
                        defaultValue={initialDate(faDate)}
                        label={'體驗日期'}
                        {...getErrorModuleStatus(errorModule, 'faDate')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectField
                        key={employeeID}
                        ref={(el) => (fieldRef.current.employeeID = el)}
                        defaultValue={employeeID}
                        label={'經辦教練'}
                        {...getErrorModuleStatus(errorModule, 'employeeID')}
                        readOnly={isReadOnly}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem disabled value="">
                            <em>請選擇</em>
                        </MenuItem>
                        {!isEmpty(coachOptions) &&
                            coachOptions.map(({ text, value }) => (
                                <MenuItem key={value} value={value}>
                                    {text}
                                </MenuItem>
                            ))}
                    </SelectField>
                </Grid>
                {['102', '103'].indexOf(String(contractVersion)) !== -1 && (
                    <React.Fragment>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}>
                            <DateField
                                key={initialDate(applyDate)}
                                ref={(el) => (fieldRef.current.applyDate = el)}
                                defaultValue={initialDate(applyDate)}
                                label={'申請日期'}
                                onChange={(_, val) => handleDateRangeFeeChange({ applyDate: val })}
                                {...getErrorModuleStatus(errorModule, 'applyDate')}
                                readOnly={isReadOnly}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <DateIntervalField
                                key={`${initialDate(reviewStartDate)}_${initialDate(reviewEndDate)}`}
                                label={'審閱期'}
                                startDateProps={{
                                    key: initialDate(reviewStartDate),
                                    defaultValue: initialDate(reviewStartDate),
                                }}
                                endDateProps={{
                                    key: initialDate(reviewEndDate),
                                    defaultValue: initialDate(reviewEndDate),
                                }}
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </React.Fragment>
                )}
            </FieldGroup>
            <FieldGroup label={'方案設定'}>
                <Grid item xs={12}>
                    <RadioField
                        key={membershipType}
                        ref={(el) => (contractMainFieldRef.current.membershipType = el)}
                        defaultValue={membershipType}
                        label={'會籍類型'}
                        onChange={handleMembershipTypeOnChange}
                        {...getErrorModuleStatus(errorModule.contractMain, 'membershipType')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                        row
                    >
                        {membershipTypeOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                {String(membershipType) === '3' && (
                    <React.Fragment>
                        <Grid item xs={4}>
                            <TextField
                                key={transfereeMemberName}
                                ref={(el) => (contractMainFieldRef.current.transfereeMemberName = el)}
                                defaultValue={transfereeMemberName}
                                label={'原會員姓名'}
                                {...getErrorModuleStatus(errorModule.contractMain, 'transfereeMemberName')}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                key={transfereeMemberNo}
                                ref={(el) => (contractMainFieldRef.current.transfereeMemberNo = el)}
                                defaultValue={transfereeMemberNo}
                                label={'原會員編號'}
                                {...getErrorModuleStatus(errorModule.contractMain, 'transfereeMemberNo')}
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CheckboxField
                                className={'mt-4'}
                                key={setCheckboxValue(useTransfereeMemberNo)}
                                ref={(el) => (contractMainFieldRef.current.useTransfereeMemberNo = el)}
                                defaultChecked={setCheckboxValue(useTransfereeMemberNo)}
                                {...getErrorModuleStatus(errorModule.contractMain, 'useTransfereeMemberNo')}
                                readOnly={isReadOnly}
                                disabled={!canTransferMemberNo}
                            >
                                <CheckboxField.Checkbox label={'使用原會員編號'} />
                            </CheckboxField>
                        </Grid>
                        {!canTransferMemberNo && (
                            <Grid item xs={12}>
                                <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                                    此會員尚有循環訂單，無法承接原會員編號
                                </Alert>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
                {String(targetType) === peopleType.invalidMember && String(membershipType) === '1' && (
                    <Grid item xs={4}>
                        <SelectField
                            key={recoveryType}
                            ref={(el) => (contractMainFieldRef.current.recoveryType = el)}
                            defaultValue={recoveryType}
                            label={'復會來源'}
                            {...getErrorModuleStatus(errorModule.contractMain, 'recoveryType')}
                            readOnly={isReadOnly}
                            fullWidth
                            required
                        >
                            {resumeSourceTypeOptions.map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                        </SelectField>
                    </Grid>
                )}
                {String(targetType) === peopleType.onlyJoin && (
                    <SourceTypeSelectField
                        ref={(el) => (contractMainFieldRef.current.sourceTypeSelector = el)}
                        options={sourceTypeOption}
                        defaultSourceType={recoveryType}
                        defaultSourceTag={sourceTag}
                        gridSize={8}
                        readOnly={isReadOnly}
                    />
                )}
                <Grid item xs={12}>
                    <RadioField
                        key={memberType}
                        ref={(el) => (contractMainFieldRef.current.memberType = el)}
                        defaultValue={memberType}
                        label={'會員類型'}
                        {...getErrorModuleStatus(errorModule.contractMain, 'memberType')}
                        onChange={(_, val) => handleChangeRangeFee(handleParams({ contractMain: { memberType: val } }))}
                        readOnly={isReadOnly}
                        required
                        row
                    >
                        {membershipMemberTypeOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                <Grid item xs={12}>
                    <RadioField
                        key={paymentType}
                        ref={(el) => (contractMainFieldRef.current.paymentType = el)}
                        defaultValue={paymentType}
                        label={'付款方式'}
                        {...getErrorModuleStatus(errorModule.contractMain, 'paymentType')}
                        onChange={(_, val) => handleChangeRangeFee(handleParams({ contractMain: { paymentType: val } }))}
                        readOnly={isReadOnly}
                        required
                        row
                    >
                        {paymentTypeOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                <Grid item xs={12}>
                    <RadioField
                        key={membershipTerm}
                        ref={(el) => (contractMainFieldRef.current.membershipTerm = el)}
                        defaultValue={membershipTerm}
                        label={'合約長度'}
                        {...getErrorModuleStatus(errorModule.contractMain, 'membershipTerm')}
                        onChange={(_, val) => handleChangeRangeFee(handleParams({ contractMain: { membershipTerm: val } }))}
                        disabled={!canChangeMembershipTerm}
                        readOnly={isReadOnly}
                        required
                        row
                    >
                        {membershipTermOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                    {!canChangeMembershipTerm && canExchange && String(targetType) === peopleType.member && (
                        <Alert severity="info" variant="filled" color="info" className="alert-justify-content-start">
                            欲修改合約長度，請走轉約流程。
                            <Button className={'ml-2'} color={'info'} variant={'contained'} onClick={() => goToContractPage(memberID)}>
                                進行轉約
                            </Button>
                        </Alert>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <DateIntervalField
                        key={`${initialDate(startDate)}_${initialDate(endDate)}`}
                        ref={(el) => (contractMainDateRef.current._ = el)}
                        label={'會籍期間'}
                        startDateProps={{
                            defaultValue: initialDate(startDate),
                            ...getErrorModuleStatus(errorModule.contractMain, 'startDate'),
                            onChange: (val) => handleMembershipDateRangeFeeChange({ contractMain: { startDate: val } }),
                        }}
                        endDateProps={{
                            defaultValue: initialDate(endDate),
                            ...getErrorModuleStatus(errorModule.contractMain, 'endDate'),
                        }}
                        disabled={!canModifyMembershipTerm}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                    {invalidMembershipStart && (
                        <Alert severity="error" variant="filled" color="error" className="alert-justify-content-start mt-2">
                            會籍起始日不能小於審閱期結束日 : {parseDate(reviewEndDate, 'YYYY/MM/DD')}
                            <br />
                            此錯誤若無修正，將在切換頁籤或下一步時重置為正確的會籍起始日
                        </Alert>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <DateIntervalField
                        key={`${initialDate(contractStartDate)}_${initialDate(contractEndDate)}`}
                        ref={(el) => (contractMainDateRef.current.contract = el)}
                        label={'合約期間'}
                        startDateProps={{
                            defaultValue: initialDate(contractStartDate),
                            ...getErrorModuleStatus(errorModule.contractMain, 'contractStartDate'),
                        }}
                        endDateProps={{
                            defaultValue: initialDate(contractEndDate),
                            ...getErrorModuleStatus(errorModule.contractMain, 'contractEndDate'),
                        }}
                        fullWidth
                        disabled
                    />
                </Grid>
            </FieldGroup>
            {String(membershipType) !== '3' && (
                <FieldGroup label="優惠折扣" required={!isReadOnly}>
                    <Grid item xs={12}>
                        <Promotions
                            ref={(el) => (contractMainFieldRef.current.plans = el)}
                            planTab={planTab || '1'}
                            defaultData={plans}
                            source={{
                                monthlyPlan,
                                enterprisePlan,
                                otherPlan,
                                contractPlan,
                            }}
                            onChange={(val) => handleChangeRangeFee(handleParams({ contractMain: { plans: val } }))}
                            readOnly={isReadOnly}
                        />
                    </Grid>
                </FieldGroup>
            )}
            <FieldGroup label="費用" required={!isReadOnly}>
                <Grid item xs={4}>
                    <TextField
                        key={joinFee}
                        ref={(el) => (contractFeeFieldRef.current.joinFee = el)}
                        defaultValue={joinFee}
                        label={'入會費'}
                        maskType={'MONEY'}
                        {...getErrorModuleStatus(errorModule.contractFee, 'joinFee')}
                        readOnly={isReadOnly}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        key={monthlyFee}
                        ref={(el) => (contractFeeFieldRef.current.monthlyFee = el)}
                        defaultValue={monthlyFee}
                        label={'月費'}
                        maskType={'MONEY'}
                        {...getErrorModuleStatus(errorModule.contractFee, 'monthlyFee')}
                        readOnly={isReadOnly}
                        fullWidth
                        required
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'其他'}>
                <Grid item xs={12}>
                    <TextField
                        key={notes}
                        ref={(el) => (contractMainFieldRef.current.notes = el)}
                        label={'備註'}
                        defaultValue={notes}
                        {...getErrorModuleStatus(errorModule.contractMain, 'notes')}
                        readOnly={isReadOnly}
                        multiline
                        rows={4}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipCreateContentPlan;
