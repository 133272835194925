import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@mui/material';
import _ from 'lodash';
import EmployeesInformationsContactsItem from '@icoach/employees/informations/EmployeesInformationsAddContactsItem';
import { HUMAN_RESOURCES_EMPLOYEES_OVERVIEW } from '@icoach/router/EmployeesRouter';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { AddressField } from '@icoach/documents/components';
import { Button, Card, Form, TextField, SelectField, MenuItem, CheckboxField, DateField, Typography, Stack } from '@common/components/';
import { getRadioFieldResult, refIsRequiredError } from '@util/utils';
import useEmployeeApi from '@apis/useEmployeeApi';
import { parseDate } from '@util/moment';

const EmployeesInformationsAddContent = React.forwardRef((props, ref) => {
    const [options, setOptions] = useState(null);
    const history = useHistory();
    const { getEmployeesRankTypeOptionsApi, postEmployeesApi } = useEmployeeApi();

    const nameRef = useRef(null);
    const jobTitleTypeRef = useRef(null);
    const nickNameRef = useRef(null);
    const birthdayRef = useRef(null);
    const identifyRef = useRef(null);
    const isForeignerRef = useRef(null);
    const permanentAddressRef = useRef(null);
    const correspondenceAddressRef = useRef(null);
    const telRef = useRef(null);
    const mobileRef = useRef(null);
    const employeesInformationsContactsItemRef = useRef(null);
    const cardNoRef = useRef(null);
    const hireDateRef = useRef(null);
    const emailRef = useRef(null);
    const heightRef = useRef(null);

    const getAllParams = () => {
        const { address: permanentAddress, postCode: permanentPostCode } = permanentAddressRef.current.getResult();
        const { address: correspondenceAddress, postCode: correspondencePostCode } = correspondenceAddressRef.current.getResult();
        const isForeigner = getRadioFieldResult(isForeignerRef.current.getResult(), 'checked', false);
        return {
            employeeID: 0,
            name: nameRef.current.getResult(),
            jobTitleType: jobTitleTypeRef.current.getResult(),
            nickName: nickNameRef.current.getResult(),
            identifierID: identifyRef.current.getResult(),
            birthday: birthdayRef.current.getResult(),
            isForeigner,
            permanentAddress,
            permanentPostCode,
            correspondenceAddress,
            correspondencePostCode,
            mobile: mobileRef.current.getResult(),
            contacts: employeesInformationsContactsItemRef.current.getResult(),
            cardNo: cardNoRef.current.getResult(),
            hireDate: hireDateRef.current.getResult(),
            email: emailRef.current.getResult(),
            height: heightRef.current.getResult(),
        };
    };

    const handleSubmit = () => {
        if (
            !refIsRequiredError(
                nameRef,
                jobTitleTypeRef,
                nickNameRef,
                birthdayRef,
                identifyRef,
                isForeignerRef,
                permanentAddressRef,
                correspondenceAddressRef,
                telRef,
                mobileRef,
                employeesInformationsContactsItemRef,
                cardNoRef,
                hireDateRef,
                emailRef,
                heightRef
            )
        ) {
            const params = getAllParams();
            doPostEmployeesDataApi(params);
        }
    };

    const handleReturnOverview = () => history.push(HUMAN_RESOURCES_EMPLOYEES_OVERVIEW);

    // 同會籍地址同步給通訊地址
    const handleSyncSamePermanentAddress = () => {
        const { address: permanentAddress, postCode: permanentPostCode } = permanentAddressRef.current.getResult();
        correspondenceAddressRef.current.resetValue(permanentAddress, permanentPostCode);
    };

    const doGetInitDataApi = async () => {
        const resp = await getEmployeesRankTypeOptionsApi();
        resp && setOptions(resp);
    };

    const doPostEmployeesDataApi = async (params) => {
        const resp = await postEmployeesApi(params);
        if (resp) {
            history.push(HUMAN_RESOURCES_EMPLOYEES_OVERVIEW);
        }
    };

    useEffect(
        () => {
            doGetInitDataApi();
        },
        // eslint-disable-next-line
        []
    );

    const rankOptions = _.isEmpty(options) ? [] : options;

    return (
        <div className={'container main-container-spacing'}>
            <header className={'page-header'}>
                <Typography className={'page-header-title'} variant={'h3'}>
                    新增員工
                </Typography>
            </header>
            <Card>
                <Card.CardContent className={'p-4'}>
                    <Form onSubmit={handleSubmit}>
                        <FieldGroup label={'基本資料'}>
                            <Grid xs={4} item>
                                <TextField ref={nameRef} label={'姓名'} fullWidth required />
                            </Grid>
                            <Grid xs={4} item>
                                <SelectField ref={jobTitleTypeRef} label={'職稱'} fullWidth required>
                                    <MenuItem value="" disabled>
                                        <em>請選擇</em>
                                    </MenuItem>
                                    {rankOptions.map(({ value, text, disabled }) => {
                                        return (
                                            <MenuItem key={value} value={value} disabled={disabled}>
                                                {text}
                                            </MenuItem>
                                        );
                                    })}
                                </SelectField>
                            </Grid>
                            <Grid xs={4} item>
                                <TextField ref={nickNameRef} label={'名牌姓名'} fullWidth required />
                            </Grid>
                            <Grid xs={4} item>
                                <DateField ref={birthdayRef} label={'出生日期'} fullWidth required />
                            </Grid>
                            <Grid xs={8} item>
                                <TextField ref={emailRef} label={'電子信箱'} type="email" fullWidth required />
                            </Grid>
                            <Grid xs={4} item>
                                <TextField ref={heightRef} label={'身高'} type="number" fullWidth required />
                            </Grid>
                            <Grid xs={4} item>
                                <TextField ref={identifyRef} label={'身分證字號'} fullWidth />
                            </Grid>
                            <Grid xs={4} item>
                                <CheckboxField ref={isForeignerRef} className={'mt-4'}>
                                    <CheckboxField.Checkbox label="非中華名國國籍" />
                                </CheckboxField>
                            </Grid>
                            <Grid xs={12} item>
                                <AddressField ref={permanentAddressRef} label={'戶籍地址'} />
                            </Grid>
                        </FieldGroup>
                        <FieldGroup label={'聯繫方式'}>
                            <Grid xs={6} item>
                                <TextField ref={telRef} label={'聯絡市話'} fullWidth />
                            </Grid>
                            <Grid xs={6} item>
                                <TextField ref={mobileRef} label={'聯絡手機'} maskType={'MOB'} fullWidth required />
                            </Grid>
                            <Grid xs={12} item>
                                <Button variant="contained" onClick={handleSyncSamePermanentAddress}>
                                    同戶籍地址
                                </Button>
                            </Grid>
                            <Grid xs={12} item>
                                <AddressField ref={correspondenceAddressRef} label={'通訊地址'} />
                            </Grid>
                        </FieldGroup>
                        <FieldGroup label={'聯絡人資料'}>
                            <EmployeesInformationsContactsItem ref={employeesInformationsContactsItemRef} />
                        </FieldGroup>
                        <FieldGroup label={'其他'}>
                            <Grid xs={4} item>
                                <TextField ref={cardNoRef} label={'簽到卡號'} fullWidth />
                            </Grid>
                            <Grid xs={4} item>
                                <DateField ref={hireDateRef} defaultValue={parseDate()} label={'到職日'} fullWidth required />
                            </Grid>
                            <Grid className={'my-2'} xs={12} item>
                                <Stack spacing={2} justifyContent="end">
                                    <Button variant={'outlined'} onClick={handleReturnOverview}>
                                        取消並返回員工總覽
                                    </Button>
                                    <Button variant={'contained'} type="submit">
                                        儲存
                                    </Button>
                                </Stack>
                            </Grid>
                        </FieldGroup>
                    </Form>
                </Card.CardContent>
            </Card>
        </div>
    );
});

export default EmployeesInformationsAddContent;
