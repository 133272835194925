import React from 'react';
import { IconButton } from '@common/components/';
import useDocumentApi from '@apis/useDocumentApi';
import useInBodyApi from '@apis/useInBodyApi';
import { Download as DownloadIcon } from '@common/SvgIcon/';

const InbodySportAdviseDialog = (props) => {
    const { sourceData } = props;
    const { getSportAdvisePDFApi } = useDocumentApi();
    const { checkSportAdviseSaveApi } = useInBodyApi();

    const handleTranscript = () => {
        const { measureID } = sourceData;
        doTranscriptApi(measureID);
    };

    const doTranscriptApi = async (measureID) => {
        await checkSportAdviseSaveApi(measureID);
        await getSportAdvisePDFApi(measureID);
    };

    return (
        <IconButton onClick={handleTranscript}>
            <DownloadIcon />
        </IconButton>
    );
};

export default InbodySportAdviseDialog;
